<template>


<div class="loader_box">
            <div class="sk-wave">
    <div class="sk-rect sk-rect-1" :style="`background-color:${selectedColor};`"></div>
    <div class="sk-rect sk-rect-2" :style="`background-color:${selectedColor};`"></div>
    <div class="sk-rect sk-rect-3" :style="`background-color:${selectedColor};`"></div>
    <div class="sk-rect sk-rect-4" :style="`background-color:${selectedColor};`"></div>
    <div class="sk-rect sk-rect-5" :style="`background-color:${selectedColor};`"></div>
  </div>
    </div>
</template>
<script>
export default{
    props:['selectedColor'],
}
</script>
<style scoped>
.loader_box{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index:999
}

.sk-wave {
  width: 75px; /* 50px * 1.5 */
  height: 50px; /* $spinkit-size */
  margin: auto;
  text-align: center;
  font-size: 1em;
}

.sk-wave .sk-rect {
  height: 100%;
  width: .5em;
  display: inline-block;
  animation: sk-wave-stretch-delay 1.2s infinite ease-in-out;
}

.sk-wave .sk-rect-1 { animation-delay: 0s; }
.sk-wave .sk-rect-2 { animation-delay: 0.1s; }
.sk-wave .sk-rect-3 { animation-delay: 0.2s; }
.sk-wave .sk-rect-4 { animation-delay: 0.3s; }
.sk-wave .sk-rect-5 { animation-delay: 0.4s; }

@keyframes sk-wave-stretch-delay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1.0);
  }
}


</style>